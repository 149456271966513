.custom-powerbi-embeded {
   
    height:100vh !important;
    
}
.container {
    position: relative;
    /* Other styles for your page container */
  }
  
  .overlap-button {
    position: absolute;
    top: 70%; /* Adjust as needed */
    right: 42%; /* Adjust as needed */
    z-index: 999; /* Ensure button is above other content */
    padding: 15px !important;
    font-size: 14px !important;
  }

  .scrollable-div {
    width: 100%; /* Adjust width as needed */
    height: 500px; /* Adjust height as needed */
    overflow: auto; /* Add scrollbar when content overflows */
  }