.routes{
    margin-left: 8px;
}

.reportName {
    cursor: pointer;
  }
  
  .reportName.collapsed {
    font-style: italic; /* Example styling for collapsed report name */
  }