/* MultiSelectDropdown.css */
.multi-select-dropdown {
    position: relative;
    width: 200px;
  }
  
  .dropdown-header {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
  }
  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 150px;
    overflow-y: auto;
    background: #fff;
    z-index: 1000;
    list-style: none;
  }
  
  .dropdown-list-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    font-size: 16px !important;

  }
  
  .dropdown-list-item:last-child {
    border-bottom: none;
  }
  